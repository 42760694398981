import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Vimeo } from "@swizec/gatsby-theme-course-platform";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "build-scalable-dataviz-components-with-full-integration"
    }}>{`Build scalable dataviz components with full integration`}</h1>
    <p><em parentName="p">{`This section builds up your mental models for dataviz components through the class-based approach. If you don't care about those details, you can jump ahead to `}<a parentName="em" {...{
          "href": "/building-blocks/5"
        }}>{`React Hooks`}</a>{`.`}</em></p>
    <Vimeo id={424604361} mdxType="Vimeo" />
    <p>{`As useful as blackbox components are, we need something better if we want to
leverage React's rendering engine. The blackbox approach in particular
struggles with scale. The more charts and graphs and visualizations on your
screen, the slower it gets.`}</p>
    <p>{`Someone once came to my workshop and said `}<em parentName="p">{`"We used the blackbox approach and
it takes several seconds to re-render our dashboard on any change. I'm here to
learn how to do it better."`}</em></p>
    <p>{`In our full-feature integration, React does the rendering and D3 calculates the
props.`}</p>
    <p>{`Our goal is to build controlled components that listen to their props and
reconcile that with D3's desire to use a lot of internal state.`}</p>
    <p>{`There are two situations we can find ourselves in:`}</p>
    <ol>
      <li parentName="ol">{`We know for a fact our component's props never change`}</li>
      <li parentName="ol">{`We think props could change`}</li>
    </ol>
    <p>{`It's easiest to show you with an example.`}</p>
    <p>{`Let's build a scatterplot step by step. Take a random array of two-dimensional
data, render in a loop. Make magic.`}</p>
    <p>{`Something like this 👇`}</p>
    <p><img parentName="p" {...{
        "src": "https://raw.githubusercontent.com/Swizec/react-d3js-es6-ebook/2018-version/manuscript/resources/images/2018/scatterplot.png",
        "alt": "A simple scatterplot"
      }}></img></p>
    <p>{`You've already built the axes! Copy pasta time.`}</p>
    <h2 {...{
      "id": "props-dont-change"
    }}>{`Props don't change`}</h2>
    <Vimeo id={424603612} mdxType="Vimeo" />
    <p>{`Ignoring props changes makes our life easier, but the component less flexible
and reusable. Great when you know in advance that there are features you don't
ned to support.`}</p>
    <p>{`Like, no filtering your data or changing component size 👉 means
your D3 scales don't have to change.`}</p>
    <p>{`When our props don't change, we follow a 2-step integration process:`}</p>
    <ul>
      <li parentName="ul">{`set up D3 objects as class properties`}</li>
      <li parentName="ul">{`output SVG in `}<inlineCode parentName="li">{`render()`}</inlineCode></li>
    </ul>
    <p>{`We don't have to worry about updating D3 objects on prop changes. Work done
👌`}</p>
    <h3 {...{
      "id": "an-unchanging-scatterplot"
    }}>{`An unchanging scatterplot`}</h3>
    <p>{`We're building a scatterplot of random data. You can see the
`}<a parentName="p" {...{
        "href": "https://codesandbox.io/s/1zlp4jv494"
      }}>{`final solution on CodeSandbox`}</a></p>
    <iframe {...{
      "src": "https://codesandbox.io/embed/1zlp4jv494",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <p>{`Here's the approach 👇`}</p>
    <ul>
      <li parentName="ul">{`stub out the basic setup`}</li>
      <li parentName="ul">{`generate random data`}</li>
      <li parentName="ul">{`stub out Scatterplot`}</li>
      <li parentName="ul">{`set up D3 scales`}</li>
      <li parentName="ul">{`render circles for each entry`}</li>
      <li parentName="ul">{`add axes`}</li>
    </ul>
    <p>{`I recommend creating a new CodeSandbox, or starting a new app with
create-react-app. They should work the same.`}</p>
    <h4 {...{
      "id": "basic-setup"
    }}>{`Basic setup`}</h4>
    <p>{`Make sure you have `}<inlineCode parentName="p">{`d3`}</inlineCode>{` added as a dependency. Then add imports in your
`}<inlineCode parentName="p">{`App.js`}</inlineCode>{` file.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// ./App.js

import * as d3 from "d3"
import Scatterplot from "./Scatterplot"
`}</code></pre>
    <p>{`Add an `}<inlineCode parentName="p">{`<svg>`}</inlineCode>{` and render a Scatterplot in the render method. This will throw
an error because we haven't defined the Scatterplot yet and that's okay.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// ./App.js

function App() {
  return (
    <div className="App">
      <h1>Hello CodeSandbox</h1>
      <h2>Start editing to see some magic happen!</h2>
      <svg width="800" height="800">
        <Scatterplot x={50} y={50} width={300} height={300} data={data} />
      </svg>
    </div>
  )
}
`}</code></pre>
    <p>{`CodeSandbox adds most of that code by default. If you're using
create-react-app, your App component has different markup. That's okay too.`}</p>
    <p>{`We added this part:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<svg width="800" height="800">
  <Scatterplot x={50} y={50} width={300} height={300} data={data} />
</svg>
`}</code></pre>
    <p>{`An `}<inlineCode parentName="p">{`<svg>`}</inlineCode>{` drawing area with a width and a height. Inside, a `}<inlineCode parentName="p">{`<Scatterplot`}</inlineCode>{`
that's positioned at `}<inlineCode parentName="p">{`(50, 50)`}</inlineCode>{` and is 300px tall and wide. We'll have to
listen to those props when building the Scatterplot.`}</p>
    <p>{`It also accepts data.`}</p>
    <h4 {...{
      "id": "random-data"
    }}>{`Random data`}</h4>
    <p>{`We're using a line of code to generate data for our scatterplot. Put it in
App.js. Either globally or within the App function. Doesn't matter because this
is an example.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const data = d3.range(100).map((_) => [Math.random(), Math.random()])
`}</code></pre>
    <p><inlineCode parentName="p">{`d3.range`}</inlineCode>{` returns a counting array from 0 to 100. Think `}<inlineCode parentName="p">{`[1,2,3,4 ...]`}</inlineCode>{`.`}</p>
    <p>{`We iterate over this array and return a pair of random numbers for each entry.
These will be our X and Y coordinates.`}</p>
    <h4 {...{
      "id": "scatterplot"
    }}>{`Scatterplot`}</h4>
    <p>{`Our scatterplot goes in a new `}<inlineCode parentName="p">{`Scatterplot.js`}</inlineCode>{` file. Starts with imports and an
empty React component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// ./Scatterplot.js
import React from "react"
import * as d3 from "d3"

class Scatterplot extends React.Component {
  render() {
    const { x, y, data, height } = this.props

    return <g transform={\`translate(\${x}, \${y})\`}></g>
  }
}

export default Scatterplot
`}</code></pre>
    <p>{`Import dependencies, create a `}<inlineCode parentName="p">{`Scatterplot`}</inlineCode>{` component, render a grouping
element moved to the correct `}<inlineCode parentName="p">{`x`}</inlineCode>{` and `}<inlineCode parentName="p">{`y`}</inlineCode>{` position. Nothing too strange yet.`}</p>
    <h4 {...{
      "id": "d3-scales"
    }}>{`D3 scales`}</h4>
    <p>{`Now we define D3 scales as component properties. We're using the class field
syntax that's common in React projects.`}</p>
    <p>{`Technically a Babel plugin, but comes by default with CodeSandbox React
projects and create-react-app setup. As far as I can tell, it's a common way to
write React components.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// ./Scatterplot.js
class Scatterplot extends React.Component {
  xScale = d3
    .scaleLinear()
    .domain([0, 1])
    .range([0, this.props.width]);
  yScale = d3
    .scaleLinear()
    .domain([0, 1])
    .range([this.props.height, 0]);
`}</code></pre>
    <p>{`We're defining `}<inlineCode parentName="p">{`this.xScale`}</inlineCode>{` and `}<inlineCode parentName="p">{`this.yScale`}</inlineCode>{` as linear scales. Their domains
go from 0 to 1 because that's what Math.random returns and their ranges
describe the size of our scatterplot component.`}</p>
    <p>{`Idea being that these two scales will help us take those tiny variations in
datapoint coordinates and explode them up to the full size of our scatterplot.
Without this, they'd overlap and we wouldn't see anything.`}</p>
    <h4 {...{
      "id": "circles-for-each-entry"
    }}>{`Circles for each entry`}</h4>
    <p>{`Rendering our data points is a matter of looping over the data and rendering a
`}<inlineCode parentName="p">{`<circle>`}</inlineCode>{` for each entry. Using our scales to define positioning.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// ./Scatterplot.js

return (
  <g transform={\`translate(\${x}, \${y})\`}>
    {data.map(([x, y]) => (
      <circle cx={this.xScale(:satisfied:} cy={this.yScale(y)} r="5" />
    ))}
  </g>
);
`}</code></pre>
    <p>{`In the `}<inlineCode parentName="p">{`return`}</inlineCode>{` statement of our `}<inlineCode parentName="p">{`render`}</inlineCode>{` render method, we add a `}<inlineCode parentName="p">{`data.map`}</inlineCode>{`
with an iterator method. This method takes our datapoint, uses array
destructuring to get `}<inlineCode parentName="p">{`x`}</inlineCode>{` and `}<inlineCode parentName="p">{`y`}</inlineCode>{` coordinates, then uses our scales to define
`}<inlineCode parentName="p">{`cx`}</inlineCode>{` and `}<inlineCode parentName="p">{`cy`}</inlineCode>{` attributes on a `}<inlineCode parentName="p">{`<circle>`}</inlineCode>{` element.`}</p>
    <h4 {...{
      "id": "add-axes"
    }}>{`Add axes`}</h4>
    <p>{`You can reuse axes from our earlier exercise. Or copy mine from
`}<a parentName="p" {...{
        "href": "https://codesandbox.io/s/1zlp4jv494"
      }}>{`the CodeSandbox`}</a></p>
    <iframe {...{
      "src": "https://codesandbox.io/embed/1zlp4jv494",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <p>{`Mine take a scale and orientation as props, which makes them more flexible.
Means we can use the same component for both the vertical and horizontal axis
on our Scatterplot.`}</p>
    <p>{`Put the axis code in `}<inlineCode parentName="p">{`Axis.js`}</inlineCode>{`, then augment the Scatterplot like this
👇`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Axis from "./Axis";

// ...

return (
  <g transform={\`translate(\${x}, \${y})\`}>
    {data.map(([x, y]) => (
      <circle cx={this.xScale(:satisfied:} cy={this.yScale(y)} r="5" />
    ))}
    <Axis x={0} y={0} scale={this.yScale} type="Left" />
    <Axis x={0} y={height} scale={this.xScale} type="Bottom" />
  </g>
);
`}</code></pre>
    <p>{`Vertical axis takes the vertical `}<inlineCode parentName="p">{`this.yScale`}</inlineCode>{` scale, orients to the `}<inlineCode parentName="p">{`Left`}</inlineCode>{` and
we position it top left. The horizontal axis takes the horizontal `}<inlineCode parentName="p">{`this.xScale`}</inlineCode>{`
scale, orients to the `}<inlineCode parentName="p">{`Bottom`}</inlineCode>{`, and we render it bottom left.`}</p>
    <p>{`Your Scatterplot should now look like this`}</p>
    <p><img parentName="p" {...{
        "src": "https://raw.githubusercontent.com/Swizec/react-d3js-es6-ebook/2018-version/manuscript/resources/images/2018/scatterplot-basic.png",
        "alt": "Rendered basic scatterplot"
      }}></img></p>
    <h2 {...{
      "id": "props-might-update"
    }}>{`Props might update`}</h2>
    <Vimeo id={424602378} mdxType="Vimeo" />
    <p>{`The story is a little different when our props might update. Since we're using
D3 objects to calculate SVG properties, we have to make sure those objects are
updated `}<em parentName="p">{`before`}</em>{` we render.`}</p>
    <p>{`No problem in React 15: Update in `}<inlineCode parentName="p">{`componentWillUpdate`}</inlineCode>{`. But since React 16.3
we've been told never to use that again. Causes problems for modern async
rendering.`}</p>
    <p>{`The official recommended solution is that anything that used to go in
`}<inlineCode parentName="p">{`componentWillUpdate`}</inlineCode>{`, can go in `}<inlineCode parentName="p">{`componentDidUpdate`}</inlineCode>{`. But not so fast!`}</p>
    <p>{`Updating D3 objects in `}<inlineCode parentName="p">{`componentDidUpdate`}</inlineCode>{` would mean our visualization always
renders one update behind. Stale renders! 😱`}</p>
    <p>{`The new `}<inlineCode parentName="p">{`getDerivedStateFromProps`}</inlineCode>{` to the rescue. Our integration follows a
3-step pattern:`}</p>
    <ul>
      <li parentName="ul">{`set up D3 objects in component state`}</li>
      <li parentName="ul">{`update D3 objects in `}<inlineCode parentName="li">{`getDerivedStateFromProps`}</inlineCode></li>
      <li parentName="ul">{`output SVG in `}<inlineCode parentName="li">{`render()`}</inlineCode></li>
    </ul>
    <p><inlineCode parentName="p">{`getDerivedStateFromProps`}</inlineCode>{` is officially discouraged, and yet the best tool we
have to make sure D3 state is updated `}<em parentName="p">{`before`}</em>{` we render.`}</p>
    <p>{`Because React calls `}<inlineCode parentName="p">{`getDerivedStateFromProps`}</inlineCode>{` on every component render, not
just when our props actually change, you should avoid recalculating complex
things too often. Use memoization helpers, check for changes before updating,
stuff like that.`}</p>
    <h3 {...{
      "id": "an-updateable-scatterplot"
    }}>{`An updateable scatterplot`}</h3>
    <p>{`Let's update our scatterplot so it can deal with resizing and updating data.`}</p>
    <p>{`3 steps 👇`}</p>
    <ul>
      <li parentName="ul">{`add an interaction that resizes the scatterplot`}</li>
      <li parentName="ul">{`move scales to state`}</li>
      <li parentName="ul">{`update scales in `}<inlineCode parentName="li">{`getDerivedStateFromProps`}</inlineCode></li>
    </ul>
    <p>{`You can see
`}<a parentName="p" {...{
        "href": "https://codesandbox.io/s/ll9kp8or0l"
      }}>{`my final solution on CodeSandbox`}</a>{`. I
recommend you follow along updating your existing code.`}</p>
    <iframe {...{
      "src": "https://codesandbox.io/embed/ll9kp8or0l",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <h4 {...{
      "id": "resize-scatterplot-on-click"
    }}>{`Resize scatterplot on click`}</h4>
    <p>{`To test our scatterplot's adaptability, we have to add an interaction: Resize
the scatterplot on click.`}</p>
    <p>{`That change happens in `}<inlineCode parentName="p">{`App.js`}</inlineCode>{`. Click on the `}<inlineCode parentName="p">{`<svg>`}</inlineCode>{`, reduce width and height
by 30%.`}</p>
    <p>{`Move sizing into App state and add an `}<inlineCode parentName="p">{`onClick`}</inlineCode>{` handler.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// App.js
class App extends React.Component {
  state = {
    width: 300,
    height: 300
  };

  onClick = () => {
    const { width, height } = this.state;
    this.setState({
      width: width * 0.7,
      height: height * 0.7
    });
  };

  render() {
    const { width, height } = this.state;
`}</code></pre>
    <p>{`We changed our App component from a function to a class, added `}<inlineCode parentName="p">{`state`}</inlineCode>{` with
default `}<inlineCode parentName="p">{`width`}</inlineCode>{` and `}<inlineCode parentName="p">{`height`}</inlineCode>{`, and an `}<inlineCode parentName="p">{`onClick`}</inlineCode>{` method that reduces size by 30%.
The `}<inlineCode parentName="p">{`render`}</inlineCode>{` method reads `}<inlineCode parentName="p">{`width`}</inlineCode>{` and `}<inlineCode parentName="p">{`height`}</inlineCode>{` from state.`}</p>
    <p>{`Now gotta change rendering to listen to these values and fire the `}<inlineCode parentName="p">{`onClick`}</inlineCode>{`
handler.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// App.js

<svg width="800" height="800" onClick={this.onClick}>
  <Scatterplot x={50} y={50} width={width} height={height} data={data} />
</svg>
`}</code></pre>
    <p>{`Similar rendering as before. We have an `}<inlineCode parentName="p">{`<svg>`}</inlineCode>{` that contains a
`}<inlineCode parentName="p">{`<Scatterplot>`}</inlineCode>{`. The svg fires `}<inlineCode parentName="p">{`this.onClick`}</inlineCode>{` on click events and the
scatterplot uses our `}<inlineCode parentName="p">{`width`}</inlineCode>{` and `}<inlineCode parentName="p">{`height`}</inlineCode>{` values for its props.`}</p>
    <p>{`If you try this code now, you should see a funny effect where axes move, but
the scatterplot doesn't resize.`}</p>
    <p><img parentName="p" {...{
        "src": "https://raw.githubusercontent.com/Swizec/react-d3js-es6-ebook/2018-version/manuscript/resources/images/2018/not-resizing-scatterplot.png",
        "alt": "Axes move, scatterplot doesn't resize"
      }}></img></p>
    <p>{`Peculiar isn't it? Try to guess why.`}</p>
    <h4 {...{
      "id": "move-scales-to-state"
    }}>{`Move scales to state`}</h4>
    <p>{`The horizontal axis moves because it's render at `}<inlineCode parentName="p">{`height`}</inlineCode>{` vertical coordinate.
Datapoints don't move because the scales that position them are calculated once
– on component mount.`}</p>
    <p>{`First step to keeping scales up to date is to move them from component values
into state.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// Scatterplot.js
class Scatterplot extends React.Component {
  state = {
    xScale: d3
      .scaleLinear()
      .domain([0, 1])
      .range([0, this.props.width]),
    yScale: d3
      .scaleLinear()
      .domain([0, 1])
      .range([this.props.height, 0])
  };
`}</code></pre>
    <p>{`Same scale definition code we had before. Linear scales, domain from 0 to 1,
using props for ranges. But now they're wrapped in a `}<inlineCode parentName="p">{`state = {}`}</inlineCode>{` object and
it's `}<inlineCode parentName="p">{`xScale: d3 ...`}</inlineCode>{` instead of `}<inlineCode parentName="p">{`xScale = d3 ...`}</inlineCode>{`.`}</p>
    <p>{`Our render function should use these as well. Small change:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// Scatterplot.js
  render() {
    const { x, y, data, height } = this.props,
      { yScale, xScale } = this.state;

    return (
      <g transform={\`translate(\${x}, \${y})\`}>
        {data.map(([x, y]) => <circle cx={xScale(:satisfied:} cy={yScale(y)} r="5" />)}
`}</code></pre>
    <p>{`We use destructuring to take our scales from state, then use them when mapping
over our data.`}</p>
    <p>{`Clicking on the SVG produces the same result as before, but we're almost there.
Just one more step.`}</p>
    <h4 {...{
      "id": "update-scales-in-getderivedstatefromprops"
    }}>{`Update scales in `}<inlineCode parentName="h4">{`getDerivedStateFromProps`}</inlineCode></h4>
    <p>{`Last step is to update our scales' ranges in `}<inlineCode parentName="p">{`getDerivedStateFromProps`}</inlineCode>{`. This
method runs every time React touches our component for any reason.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// Scatterplot.js
class Scatterplot extends React.PureComponent {
  // ..
  static getDerivedStateFromProps(props, state) {
    const { yScale, xScale } = state;

    yScale.range([props.height, 0]);
    xScale.range([0, props.width]);

    return {
      ...state,
      yScale,
      xScale
    };
  }
`}</code></pre>
    <p>{`Take scales from state, update ranges with new values, return new state. Nice
and easy.`}</p>
    <p>{`Notice that `}<inlineCode parentName="p">{`getDerivedStateFromProps`}</inlineCode>{` is a static method shared by all
instances of our Scatterplot component. You have no reference to a `}<inlineCode parentName="p">{`this`}</inlineCode>{` and
have to calculate new state purely from the `}<inlineCode parentName="p">{`props`}</inlineCode>{` and `}<inlineCode parentName="p">{`state`}</inlineCode>{` passed into
your method.`}</p>
    <p>{`It's a lot like a Redux reducer, if that helps you think about it. If you don't
know what Redux reducers are, don't worry. Just remember to return a new
version of component state.`}</p>
    <p>{`Your Scatterplot should now update its size on every click.`}</p>
    <p><img parentName="p" {...{
        "src": "https://raw.githubusercontent.com/Swizec/react-d3js-es6-ebook/2018-version/manuscript/resources/images/2018/scatterplot-resizes.png",
        "alt": "Scatterplot resizes"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      